<template>
    <!-- 内容 -->
    <div class="index-new">
        <div class="new-content">
            <div class="new-title">学会活动</div>
            <div class="new-list">
                <div class="new-item" v-for="(item, index) in list" :key="index" @click="navto(item)">
                    <img :src="item.image"/>
                    <div class="new-item-info">
                        <div class="new-item-info-title">{{ item.title }}</div>
                        <p>{{ item.describe}}</p>
                    </div>
                    <div class="new-item-time">
                        <span>{{ item.releasetime_text[1]}}</span>
                        <div class="new-tim-line"></div>
                        <div class="new-time-year">{{ item.releasetime_text[0]}}</div>
                    </div>
                </div>
            </div>
            <!-- 分页 -->
            <paging @changeIndex="changeIndex" :allNum="total"></paging>
        </div>
        
    </div>
</template>
<script>
import paging from '@/components/paging'
export default {
    name: 'societyNew',
    components:{
        paging
    },
    data(){
        return{
            list:[],
            page:1,//页
            limit:6,//条
            total:0,//总页数
            count:0,//总条数
        }
    },
    created() {
        this.getList()
    },
    methods: {
        getList(){
            this.$request({
                url:'/api/article/snew',
                method:'POST',
                data:{
                    page:this.page,
                    limit:this.limit
                }
            }).then(res=>{
                if(res.code==1){
                    console.log(res,'学会新闻')
                    this.list=res.data.list
                    this.count=res.data.count
                    this.total=res.data.total
                }
            })
        },  
        navto(item){
            this.$router.push({
                path:"/news/newDetail",
                query:{
                    id:item.id
                }
            })
        },
        changeIndex(e){
            console.log(e) 
            this.page=e
            this.getList()
        },
    }
}
</script>
<style lang="scss" scoped>
    .index-new{
        width: 100%;
        display: flex;
        justify-content: center;
        background: #F6F6EA;
    }
    // 内容
    .new-content{
        width: 1200px;
        background: #ffffff;
        display: flex;
        flex-direction: column;
        padding: 60px 40px;
    }
    .new-title{
        padding-bottom: 12px;
        margin-bottom: 24px;
        color: #272727;
        font-size: 30px;
        text-align: center;
        font-weight: bold;
    }
    .new-list{
        display: flex;
        flex-direction: column;
    }
    .new-item{
        display: flex;
        align-items: center;
        padding-bottom: 30px;
        margin-bottom: 30px;
        cursor: pointer;
        border-bottom: 1px solid #ccc;
    }
    .new-item-info{
        flex: 1;
        padding: 0 44px;
        display: flex;
        flex-direction: column;
    }
    .new-item-info-title{
        color: #313131;
        font-size: 20px;
        padding-bottom: 30px;
    }
    .new-item-info p{
        color: #7D7D7D;
    }
    .new-item img{
        width: 300px;
        height: 213px;
    }
    .new-item-time{
        flex-shrink: 0;
        padding: 24px 0 24px 35px;
        border-left: 1px dashed #707070;
        color: #313131;
        font-size: 26px;
        font-weight: bold;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .new-tim-line{
        margin: 13px 0 6px;
        width: 49px;
        height: 0;
        border: 1px solid #C1C1C1;
    }
    .new-time-year{
        color: #707070;
        font-size: 16px;
        font-weight: 400;
    }
</style>